<template>
  <CheckMaterialMobile v-if="isMobileAgent()" :item="item" @cancel="cancel" @refreshData="refreshData" />
  <CheckMaterialDesktop v-else :item="item" @cancel="cancel" @refreshData="refreshData" />
</template>

<script>

export default {
  name: "CheckMaterial",
  components: {
    CheckMaterialMobile: () => import('@/components/material/CheckMaterialMobile'),
    CheckMaterialDesktop: () => import('@/components/material/CheckMaterialDesktop'),
  },
  props: {
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    refreshData(type) {
      this.$emit('refreshData', type)
    },
  }
}
</script>

<style scoped>

</style>
